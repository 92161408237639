import React from 'react';
import cn from 'classnames';
import { useKeywordSearch } from '../../../../hooks';
import styles from './SearchField.module.scss';
import { SearchButton } from '../SearchButton';

export enum SearchFieldVariants {
  search = 'search',
  button = 'button',
}

export interface SearchFieldProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onKeyDown' | 'onInput'
  > {
  variant: keyof typeof SearchFieldVariants;
  onSearch: (value: string) => void;
}

export function SearchField({
  variant,
  onSearch,
  placeholder,
  ...props
}: SearchFieldProps) {
  const {
    value,
    handleChange,
    handleKeyDown,
    handleSearch: onSearchCustom,
  } = useKeywordSearch(onSearch);

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.asButton]: variant === SearchFieldVariants.button,
      })}
    >
      <input
        type="text"
        value={value}
        onInput={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder || 'Enter your search term or a topic'}
        {...props}
      />
      <div className={styles.button}>
        <SearchButton onClick={() => onSearchCustom(value)} />
      </div>
    </div>
  );
}

SearchField.defaultProps = {
  variant: SearchFieldVariants.search,
};
