import React from 'react';
import cn from 'classnames';
import { HubNavBarItem } from '../../../../../types';
import { HubNavBarItemTypes } from '../../../../../constants';
import { useHubContext } from '../../context/hubContext';
import styles from './Link.module.scss';
import { Anchor } from '../../../../shared/Anchor';

export interface LinkProps {
  link: HubNavBarItem;
}

export function Link({ link }: LinkProps) {
  const { indexPath } = useHubContext();

  return (
    <Anchor
      to={link.href}
      pathPrefix={
        link['@type'] === HubNavBarItemTypes.CustomLink ? '' : [indexPath]
      }
      openInNewTab={link.openInNewTab}
      className={cn(styles.anchor, 'hub-nav-item')}
    >
      <span className={styles.label}>{link.label}</span>
    </Anchor>
  );
}
