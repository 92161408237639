import React from 'react';
import cn from 'classnames';
import { HubNavBarItem } from '../../../../../types';
import { useHubContext } from '../../context/hubContext';
import styles from './HomeLink.module.scss';
import { Anchor } from '../../../../shared/Anchor';

export interface HomeLinkProps {
  link: HubNavBarItem;
}

export function HomeLink({ link }: HomeLinkProps) {
  const { indexPath } = useHubContext();

  return (
    <Anchor
      to="/"
      pathPrefix={indexPath}
      className={cn(styles.anchor, 'hub-nav-home-item')}
    >
      <span className={styles.label}>{link.label}</span>
      <span className={cn('material-icons', styles.icon)}>home</span>
    </Anchor>
  );
}
