import React from 'react';
import cn from 'classnames';
import { HubNavBarBaseItem } from '../../../../../types';
import { HubNavBarItemTypes } from '../../../../../constants';
import { useHubContext } from '../../context/hubContext';
import styles from './SecondLevelMenu.module.scss';
import { Anchor } from '../../../../shared/Anchor';

export interface SecondLevelMenuProps {
  links: HubNavBarBaseItem[];
  fullScreen?: boolean;
  alignRight?: boolean;
}

export function SecondLevelMenu(props: SecondLevelMenuProps) {
  const { links, fullScreen } = props;
  const { indexPath } = useHubContext();

  const containerClassName = cn({
    'container-fluid': fullScreen,
    container: !fullScreen,
  });

  return (
    <div className={styles.wrapper}>
      <div className={containerClassName}>
        <div className="row">
          <div className="col-xs-12">
            <ul
              className={cn(styles.list, {
                [styles.alignRight]: false,
              })}
            >
              {links.map((link) => (
                <li key={link.id} className={styles.listItem}>
                  <Anchor
                    to={link.href}
                    pathPrefix={
                      link['@type'] === HubNavBarItemTypes.CustomLink
                        ? ''
                        : [indexPath]
                    }
                    openInNewTab={link.openInNewTab}
                    className={cn(styles.anchor, 'hub-nav-sub-item')}
                  >
                    {link.label}
                  </Anchor>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
