import s from './ButtonLink.module.scss';
import { HubNavBarItem } from '../../../../../types';
import { HubNavBarItemTypes } from '../../../../../constants';
import { useHubContext } from '../../context/hubContext';
import { Button } from '../../Button';

export interface ButtonLinkProps {
  link: HubNavBarItem;
}

export function ButtonLink({ link }: ButtonLinkProps) {
  const { indexPath } = useHubContext();

  return (
    <Button
      to={link.href}
      pathPrefix={
        link['@type'] === HubNavBarItemTypes.CustomLink ? '' : [indexPath]
      }
      size="small"
      className={s.button}
    >
      {link.label}
    </Button>
  );
}
