import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { HubNavBarItem } from '../../../../../types';
import styles from './DropdownGroupLink.module.scss';
import { SecondLevelMenu } from '../SecondLevelMenu';
import { useDeviceDetect } from '../../../../../hooks';

export interface DropdownGroupLinkProps {
  link: HubNavBarItem;
  fullScreen?: boolean;
  alignRight?: boolean;
}

export function DropdownGroupLink(props: DropdownGroupLinkProps) {
  const { link, fullScreen, alignRight } = props;
  const { pathname } = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  const groupActive = useMemo(() => {
    return link.groupItems.some((groupItem) => {
      const regExp = new RegExp(`${groupItem.href}/?$`);
      return pathname.search(regExp) > -1;
    });
  }, [link.groupItems, pathname]);

  const { isDesktop } = useDeviceDetect();

  return (
    <div
      className={cn(styles.anchor, {
        [styles.open]: open,
      })}
    >
      <span
        onClick={() => !isDesktop && setOpen(!open)}
        className={cn(styles.label, 'hub-nav-group-item', {
          active: groupActive,
        })}
      >
        {link.label}
        <span className={cn('material-icons', styles.icon)}>
          {open ? 'expand_less' : 'expand_more'}
        </span>
      </span>
      <div className={styles.secondLevelMenu}>
        <SecondLevelMenu
          links={link.groupItems}
          fullScreen={fullScreen}
          alignRight={alignRight}
        />
      </div>
    </div>
  );
}
